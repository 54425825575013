<template>
  <HostLayoutContainer class="contact" :style="{height:'820px'}">
    <HostLayoutItem>
      <Title :title="{text: '联系我们', marginTop: '104px', marginBottom: '54px'}" id="contact"/>
      <!--<div class="contact__title">联系我们</div>-->
      <div class="contact__content">
        <div class="contact__cards">
          <div class="contact__card contact__address">
            <div class="contact__card-title">
              <div class="contact__card-title-inner">
                <div>有帮信息科技(北京)有限公司</div>
                <div class="contact__icon"><img src="/assets/about/contact_location@3x.png" alt=""></div>
              </div>
            </div>

            <div class="contact__text">地址：北京市朝阳区将台路甲<br />2号院2号楼1层101号9层902室</div>
            <div class="contact__text">邮编：100016</div>
          </div>
          <div class="contact__card contact__contact-way">

            <div class="contact__card-title">
              <div class="contact__card-title-inner">
                <div>联系我们</div>
                <div class="contact__icon"><img src="/assets/about/contact_ways@3x.png" alt=""></div>
              </div>
            </div>
            <div class="contact__text">
              咨询邮箱：info@ubang.cn <br />
              销售邮箱：sales@ubang.cn <br />
              市场邮箱：pr@ubang.cn <br />
              招聘邮箱：hr@ubang.cn <br />
            </div>
          </div>
        </div>
        <div class="contact__map">
          <div class="contact__map-image"><img src="/assets/about/contact_map@3x.png" alt=""></div>
        </div>
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>

<script>
import {defineComponent} from 'vue'
import Title from '@/components/title/title-with-underline.vue'
export default defineComponent({
  components: {Title}
})
</script>

<style lang="scss" scoped>
.contact {
  background-image: url('/assets/solution/banner@3x.png');
  background-position: bottom center;
}

//.contact__title {
//  display: flex;
//  justify-content: center;
//  margin-top: 83px;
//  margin-bottom: 79px;
//
//  font-size: 32px;
//  font-family: PingFangSC-Medium, PingFang SC;
//  font-weight: 500;
//  color: #0D253E;
//  line-height: 45px;
//  letter-spacing: 2px;
//}

.contact .description__title {
  margin-top: 50px;
}

.contact__content {
  display: flex;
  justify-content: space-between;

  padding: 0 40px;
}

.contact__cards {
  width: 320px;
}

.contact__card {
  overflow: hidden; /* 触发 bfc */
  padding-left: 32px;
  padding-right: 18px;
  height: 227px;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.contact__card:last-child {
  margin-top: 48px;
}

.contact__card-title {
  margin-top: 28px;

  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #3F4957;
  line-height: 22px;
}

.contact__card-title-inner {
  display: flex;
  align-items: center;
}

.contact__icon {
  margin-left: 10px;
  width: 26px;
  img {
    height: auto;
  }
}

.contact__text {
  margin-top: 16px;

  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #818C9D;
  line-height: 27px;
}

.contact__map {
  width: 765px;
  height: 502px;
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.08), 0px 3px 10px 0px rgba(100, 39, 8, 0.05);
  border-radius: 24px;
}

.contact__map-image {
  padding: 24px;
}

</style>
